import { FaFootballBall, FaCalendarAlt, FaRandom } from 'react-icons/fa';
import { BsGraphDown, BsGraphUp, BsGripHorizontal } from 'react-icons/bs';
import { TbGpsFilled } from 'react-icons/tb';
import {
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
  MdAlignVerticalBottom,
  MdBarChart,
  MdSportsSoccer,
} from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { MatchList } from './MatchList';
import {
  getDrawPredictions,
  getExactResultPredictions,
  getGoalGoalPredictions,
  getNoGoalPredictions,
  getOver2Point5Predictions,
  getUnder3Point5Predictions,
  MainMatchesPrevisionData,
  getOver1Point5Predictions,
  getMultigol2_5Predictions,
  getMultigol1_3Predictions,
  getMultigol1_3HomePredictions,
  getMultigol1_3AwayPredictions,
  getUnder1Point5HomePredictions,
  getUnder1Point5AwayPredictions,
} from './MainMatchesData';
import { PageTitle } from './PageTitle';
import { Page } from './Page';
import { TailSpin } from 'react-loader-spinner';

const MainPage = () => {
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedDate') ||
      new Date().toISOString().split('T')[0],
  );
  const [selectedBet, setSelectedBet] = useState(
    localStorage.getItem('selectedBet') || 'GoalGoal',
  );
  const [selectedLeague, setSelectedLeague] = useState(
    localStorage.getItem('selectedLeague') || '',
  );

  const [matches, setMatches] = useState<MainMatchesPrevisionData[]>([]);
  const [matchesLoading, setMatchesLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const leagueInputRef = useRef<HTMLSelectElement>(null);

  const leagues = [
    { id: 'Bundesliga 1', name: 'Bundesliga 1' },
    { id: 'Bundesliga 2', name: 'Bundesliga 2' },
    { id: 'Eredivisie', name: 'Eredivisie' },
    { id: 'La Liga', name: 'La Liga' },
    { id: 'League 2', name: 'League 2' },
    { id: 'Liga Portugal', name: 'Liga Portugal' },
    { id: 'Ligue 1', name: 'Ligue 1' },
    { id: 'Premier League', name: 'Premier League' },
    { id: 'Serie A', name: 'Serie A' },
    { id: 'Serie B', name: 'Serie B' },
    { id: 'Super League', name: 'Super League' },
    { id: 'Super Lig', name: 'Super Lig' },
  ];

  // Save filters when user selects a new filter
  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('selectedBet', selectedBet);
  }, [selectedBet]);

  useEffect(() => {
    localStorage.setItem('selectedLeague', selectedLeague);
  }, [selectedLeague]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    if (!localStorage.getItem('selectedDate')) {
      setSelectedDate(today);
    }
    if (dateInputRef.current) {
      if (selectedDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }
    const leagueVal = '';
    if (!localStorage.getItem('selectedLeague')) {
      setSelectedLeague(leagueVal);
    }
    if (leagueInputRef.current) {
      if (selectedLeague == 'All') {
        leagueInputRef.current.classList.add('bg-white');
        leagueInputRef.current.classList.remove(
          'bg-customPurple',
          'text-white',
        );
      } else {
        leagueInputRef.current.classList.add('bg-customPurple', 'text-white');
        leagueInputRef.current.classList.remove('bg-white');
      }
    }

    handleButtonClick(selectedBet, selectedDate, selectedLeague);
  }, []);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Ottieni la data corrente in formato YYYY-MM-DD
    const today = new Date().toISOString().split('T')[0];

    // Modifica la classe dell'input in base alla data selezionata
    if (dateInputRef.current) {
      if (newDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }

    // Passa la nuova data e il tipo selezionato per l'aggiornamento
    handleButtonClick(selectedBet, newDate, selectedLeague);
  };

  const handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVal = event.target.value;
    setSelectedLeague(newVal);
    if (leagueInputRef.current) {
      if (newVal == 'All') {
        leagueInputRef.current.classList.add('bg-white');
        leagueInputRef.current.classList.remove(
          'bg-customPurple',
          'text-white',
        );
      } else {
        leagueInputRef.current.classList.add('bg-customPurple', 'text-white');
        leagueInputRef.current.classList.remove('bg-white');
      }
    }
    handleButtonClick(selectedBet, selectedDate, newVal);
  };

  const doGetGoalGoalPredictions = async (date: string, league: string) => {
    const retMatches = await getGoalGoalPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetNoGoalPredictions = async (date: string, league: string) => {
    const retMatches = await getNoGoalPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetOver2Point5Predictions = async (date: string, league: string) => {
    const retMatches = await getOver2Point5Predictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetUnder3Point5Predictions = async (date: string, league: string) => {
    const retMatches = await getUnder3Point5Predictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetDrawPredictions = async (date: string, league: string) => {
    const retMatches = await getDrawPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetOver1Point5ResultPredictions = async (
    date: string,
    league: string,
  ) => {
    const retMatches = await getOver1Point5Predictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetMultigol2_5Predictions = async (date: string, league: string) => {
    const retMatches = await getMultigol2_5Predictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetMultigol1_3Predictions = async (date: string, league: string) => {
    const retMatches = await getMultigol1_3Predictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetMultigol1_3HomePredictions = async (
    date: string,
    league: string,
  ) => {
    const retMatches = await getMultigol1_3HomePredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const doGetMultigol1_3AwayPredictions = async (
    date: string,
    league: string,
  ) => {
    const retMatches = await getMultigol1_3AwayPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };
  const doGetUnder1Point5HomePredictions = async (
    date: string,
    league: string,
  ) => {
    const retMatches = await getUnder1Point5HomePredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };
  const doGetUnder1Point5AwayPredictions = async (
    date: string,
    league: string,
  ) => {
    const retMatches = await getUnder1Point5AwayPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };
  const doGetExactResultPredictions = async (date: string, league: string) => {
    const retMatches = await getExactResultPredictions(date, league); // Passa la data come parametro
    setMatches(retMatches);
  };

  const handleButtonClick = async (
    type: string,
    date?: string,
    league?: string,
  ) => {
    setMatchesLoading(true); // Inizia il caricamento
    const loadingStartTime = Date.now(); // Salva il tempo di inizio del caricamento
    const searchDate = date || selectedDate;
    let searchLeague = league || selectedLeague;

    if (searchLeague === 'All') searchLeague = '';

    // Gestione delle chiamate asincrone in base al tipo
    switch (type) {
      case 'GoalGoal':
        await doGetGoalGoalPredictions(searchDate, searchLeague);
        break;
      case 'NoGoal':
        await doGetNoGoalPredictions(searchDate, searchLeague);
        break;
      case 'Over2.5':
        await doGetOver2Point5Predictions(searchDate, searchLeague);
        break;
      case 'Under3.5':
        await doGetUnder3Point5Predictions(searchDate, searchLeague);
        break;
      case 'Draw':
        await doGetDrawPredictions(searchDate, searchLeague);
        break;
      case 'ExactResult':
        await doGetExactResultPredictions(searchDate, searchLeague);
        break;
      case 'Over1.5':
        await doGetOver1Point5ResultPredictions(searchDate, searchLeague);
        break;
      case 'Multigol2_5':
        await doGetMultigol2_5Predictions(searchDate, searchLeague);
        break;
      case 'Multigol1_3':
        await doGetMultigol1_3Predictions(searchDate, searchLeague);
        break;
      case 'Multigol1_3H':
        await doGetMultigol1_3HomePredictions(searchDate, searchLeague);
        break;
      case 'Multigol1_3A':
        await doGetMultigol1_3AwayPredictions(searchDate, searchLeague);
        break;
      case 'Under1.5H':
        await doGetUnder1Point5HomePredictions(searchDate, searchLeague);
        break;
      case 'Under1.5A':
        await doGetUnder1Point5AwayPredictions(searchDate, searchLeague);
        break;
      default:
        break;
    }

    // Tempo di caricamento minimo
    const minLoadingTime = 400;
    const timeout = setTimeout(() => {
      if (Date.now() - loadingStartTime >= minLoadingTime) {
        setMatchesLoading(false);
      }
    }, minLoadingTime);

    await new Promise<void>((resolve) =>
      setTimeout(
        resolve,
        Math.max(0, minLoadingTime - (Date.now() - loadingStartTime)),
      ),
    );

    setMatchesLoading(false);
  };

  const betTypes = [
    { id: 'GoalGoal', label: 'Both Teams to Score', icon: <FaRandom /> },
    { id: 'Over1.5', label: 'Over 1.5', icon: <BsGraphUp /> },
    { id: 'Under3.5', label: 'Under 3.5', icon: <BsGraphDown /> },
    { id: 'Multigol2_5', label: 'Multi 2-5', icon: <MdBarChart /> },
    { id: 'Multigol1_3', label: 'Multi 1-3', icon: <MdAlignVerticalBottom /> },
    {
      id: 'Multigol1_3H',
      label: 'Multi 1-3 Home',
      icon: <MdAlignHorizontalLeft />,
    },
    {
      id: 'Multigol1_3A',
      label: 'Multi 1-3 Away',
      icon: <MdAlignHorizontalRight />,
    },
    { id: 'Under1.5H', label: 'Under 1.5 Home', icon: <BsGraphDown /> },
    { id: 'Under1.5A', label: 'Under 1.5 Away', icon: <BsGraphDown /> },
    // { id: 'Draw', label: 'Draw', icon: <BsGripHorizontal /> },
    { id: 'ExactResult', label: 'Result', icon: <MdSportsSoccer /> },
  ];
  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 mt-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8 text-stroke">
          Football Match Predictions
        </h1>
        {matchesLoading && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center">
            <TailSpin color="#fff" height={50} width={50} />
          </div>
        )}
        <div className="mb-6 flex flex-wrap items-center justify-center gap-4">
          {betTypes.map((betType) => (
            <button
              key={betType.id}
              onClick={() => {
                setSelectedBet(betType.id);
                handleButtonClick(betType.id);
              }}
              className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                selectedBet === betType.id
                  ? 'bg-customPurple text-white border border-gray-300'
                  : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
            >
              <span className="mr-2">{betType.icon}</span>
              {betType.label}
            </button>
          ))}

          <div className="relative">
            <input
              type="date"
              id="matchDate"
              name="matchDate"
              value={selectedDate}
              onChange={handleDateChange}
              ref={dateInputRef}
              className="appearance-none pl-10 pr-4 py-2 h-10 rounded-full text-sm bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Select a date"
            />
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          </div>

          {/* Filtro per campionato */}
          <div className="relative">
            <select
              value={selectedLeague}
              onChange={handleLeagueChange}
              ref={leagueInputRef}
              className="h-10 pl-10 pr-4 py-2 rounded-full text-sm bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
            >
              <option value="All">All Leagues</option>
              {leagues.map((league) => (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              ))}
            </select>
            <TbGpsFilled className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          </div>
        </div>
        <div className="overflow-x-auto shadow-md rounded-lg">
          {matchesLoading ? (
            <div className="text-center py-4 text-gray-200">Loading...</div>
          ) : matches && matches.length > 0 ? (
            <MatchList data={matches} />
          ) : (
            <div className="text-center py-4 text-gray-200">
              No matches found for the selected criteria.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
