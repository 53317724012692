import {
  FaFootballBall,
  FaCalendarAlt,
  FaRandom,
  FaUndoAlt,
  FaDice,
} from 'react-icons/fa';
import { BsGraphDown, BsGraphUp, BsGripHorizontal } from 'react-icons/bs';
import { TbGpsFilled } from 'react-icons/tb';
import { FaTrash } from 'react-icons/fa';
import {
  MdAlignHorizontalLeft,
  MdAlignHorizontalRight,
  MdAlignVerticalBottom,
  MdBarChart,
  MdSportsSoccer,
} from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { MatchListBetGeneration } from './MatchListBetGenaration';
import {
  getDrawSingleRandomPrediction,
  getExactResultSingleRandomPrediction,
  getGoalGoalSingleRandomPrediction,
  getNoGoalSingleRandomPrediction,
  getOver2Point5SingleRandomPrediction,
  getUnder3Point5SingleRandomPrediction,
  MainMatchesPrevisionData,
  getOver1Point5SingleRandomPrediction,
  getMultigol2_5SingleRandomPrediction,
  getMultigol1_3SingleRandomPrediction,
  getMultigol1_3HomeSingleRandomPrediction,
  getMultigol1_3AwaySingleRandomPrediction,
  getUnder1Point5HomeSingleRandomPrediction,
  getUnder1Point5AwaySingleRandomPrediction,
} from './MainMatchesData';
import { PageTitle } from './PageTitle';
import { Page } from './Page';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from 'react-loader-spinner';

const BetGenerationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedDateBetGen') ||
      new Date().toISOString().split('T')[0],
  );
  const [selectedBet, setSelectedBet] = useState(
    localStorage.getItem('selectedBetBetGen') || 'GoalGoal',
  );
  const [selectedLeague, setSelectedLeague] = useState(
    localStorage.getItem('selectedLeagueBetGen') || '',
  );

  const [tableData, setTableData] = useState<any[]>([]);
  const [matches, setMatches] = useState<MainMatchesPrevisionData[]>([]);
  const [matchesLoading, setMatchesLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const leagueInputRef = useRef<HTMLSelectElement>(null);
  const [loadingTime, setLoadingTime] = useState(0);

  const leagues = [
    { id: 'Bundesliga 1', name: 'Bundesliga 1' },
    { id: 'Bundesliga 2', name: 'Bundesliga 2' },
    { id: 'Eredivisie', name: 'Eredivisie' },
    { id: 'La Liga', name: 'La Liga' },
    { id: 'League 2', name: 'League 2' },
    { id: 'Liga Portugal', name: 'Liga Portugal' },
    { id: 'Ligue 1', name: 'Ligue 1' },
    { id: 'Premier League', name: 'Premier League' },
    { id: 'Serie A', name: 'Serie A' },
    { id: 'Serie B', name: 'Serie B' },
    { id: 'Super League', name: 'Super League' },
    { id: 'Super Lig', name: 'Super Lig' },
  ];

  // Save filters when user selects a new filter
  useEffect(() => {
    localStorage.setItem('selectedDateBetGen', selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    localStorage.setItem('selectedBetBetGen', selectedBet);
  }, [selectedBet]);

  useEffect(() => {
    localStorage.setItem('selectedLeagueBetGen', selectedLeague);
  }, [selectedLeague]);

  // Salva `tableData` in LocalStorage quando cambia
  useEffect(() => {
    if (tableData.length > 0) {
      localStorage.setItem('tableDataBetGen', JSON.stringify(tableData));
    }
  }, [tableData]);

  // Carica `tableData` da LocalStorage quando la pagina viene caricata
  useEffect(() => {
    const savedData = localStorage.getItem('tableDataBetGen');
    if (savedData) {
      setTableData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    if (!localStorage.getItem('selectedDateBetGen')) {
      setSelectedDate(today);
    }
    if (dateInputRef.current) {
      if (selectedDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }
    const leagueVal = '';
    if (!localStorage.getItem('selectedLeagueBetGen')) {
      setSelectedLeague(leagueVal);
    }
    if (leagueInputRef.current) {
      if (selectedLeague == 'All') {
        leagueInputRef.current.classList.add('bg-white');
        leagueInputRef.current.classList.remove(
          'bg-customPurple',
          'text-white',
        );
      } else {
        leagueInputRef.current.classList.add('bg-customPurple', 'text-white');
        leagueInputRef.current.classList.remove('bg-white');
      }
    }
  }, []);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // Ottieni la data corrente in formato YYYY-MM-DD
    const today = new Date().toISOString().split('T')[0];

    // Modifica la classe dell'input in base alla data selezionata
    if (dateInputRef.current) {
      if (newDate === today) {
        dateInputRef.current.classList.add('bg-white');
        dateInputRef.current.classList.remove('bg-customPurple', 'text-white');
      } else {
        dateInputRef.current.classList.add('bg-customPurple', 'text-white');
        dateInputRef.current.classList.remove('bg-white');
      }
    }
  };

  const handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVal = event.target.value;
    setSelectedLeague(newVal);
    if (leagueInputRef.current) {
      if (newVal == 'All') {
        leagueInputRef.current.classList.add('bg-white');
        leagueInputRef.current.classList.remove(
          'bg-customPurple',
          'text-white',
        );
      } else {
        leagueInputRef.current.classList.add('bg-customPurple', 'text-white');
        leagueInputRef.current.classList.remove('bg-white');
      }
    }
  };

  const doGetGoalGoalSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getGoalGoalSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    );
    return retMatches;
  };

  const doGetNoGoalSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getNoGoalSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetOver2Point5SingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getOver2Point5SingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetUnder3Point5SingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getUnder3Point5SingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetDrawSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getDrawSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetOver1Point5ResultSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getOver1Point5SingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetMultigol2_5SingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getMultigol2_5SingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetMultigol1_3SingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getMultigol1_3SingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetMultigol1_3HomeSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getMultigol1_3HomeSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const doGetMultigol1_3AwaySingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getMultigol1_3AwaySingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };
  const doGetUnder1Point5HomeSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getUnder1Point5HomeSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };
  const doGetUnder1Point5AwaySingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getUnder1Point5AwaySingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };
  const doGetExactResultSingleRandomPrediction = async (
    date: string,
    league: string,
    excludedPredictionIds: string,
  ) => {
    const retMatches = await getExactResultSingleRandomPrediction(
      date,
      league,
      excludedPredictionIds,
    ); // Passa la data come parametro
    return retMatches;
  };

  const handleButtonClick = async (
    type: string,
    date?: string,
    league?: string,
  ) => {
    setIsLoading(true); // Inizia il caricamento
    setLoadingTime(Date.now()); // Salva il tempo di inizio del caricamento

    const searchDate = date || selectedDate;
    let searchLeague = league || selectedLeague;
    const excludedPredictionIds = tableData
      .map((m) => m.PredictionId)
      .join(',');

    if (searchLeague === 'All') searchLeague = '';

    let result: MainMatchesPrevisionData | null = null;

    // Gestione delle chiamate asincrone in base al tipo
    switch (type) {
      case 'GoalGoal':
        result = await doGetGoalGoalSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'NoGoal':
        result = await doGetNoGoalSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Over2.5':
        result = await doGetOver2Point5SingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Under3.5':
        result = await doGetUnder3Point5SingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Draw':
        result = await doGetDrawSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'ExactResult':
        result = await doGetExactResultSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Over1.5':
        result = await doGetOver1Point5ResultSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Multigol2_5':
        result = await doGetMultigol2_5SingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Multigol1_3':
        result = await doGetMultigol1_3SingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Multigol1_3H':
        result = await doGetMultigol1_3HomeSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Multigol1_3A':
        result = await doGetMultigol1_3AwaySingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Under1.5H':
        result = await doGetUnder1Point5HomeSingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      case 'Under1.5A':
        result = await doGetUnder1Point5AwaySingleRandomPrediction(
          searchDate,
          searchLeague,
          excludedPredictionIds,
        );
        break;
      default:
        break;
    }

    // Tempo di caricamento minimo
    const minLoadingTime = 400;
    const loadingStartTime = Date.now();
    const timeout = setTimeout(() => {
      if (Date.now() - loadingStartTime >= minLoadingTime) {
        setIsLoading(false);
      }
    }, minLoadingTime);

    await new Promise<void>((resolve) =>
      setTimeout(
        resolve,
        Math.max(0, minLoadingTime - (Date.now() - loadingStartTime)),
      ),
    );

    // Gestione del risultato
    if (result != null) {
      setTableData((prevData) => {
        const exists = prevData.some(
          (item) =>
            item.MatchId === result!.MatchId &&
            result!.PredictionType === item.PredictionType,
        );
        if (!exists) {
          return [...prevData, result!];
        }
        return prevData;
      });
    } else {
      toast.warning('No results found. Try changing filters.', {
        position: 'bottom-center',
        autoClose: 2000,
        style: {
          backgroundColor: 'white',
          color: 'purple',
        },
      });
    }

    setIsLoading(false);
  };

  const betTypes = [
    { id: 'GoalGoal', label: 'Both Teams to Score', icon: <FaRandom /> },
    { id: 'Over1.5', label: 'Over 1.5', icon: <BsGraphUp /> },
    { id: 'Under3.5', label: 'Under 3.5', icon: <BsGraphDown /> },
    { id: 'Multigol2_5', label: 'Multi 2-5', icon: <MdBarChart /> },
    { id: 'Multigol1_3', label: 'Multi 1-3', icon: <MdAlignVerticalBottom /> },
    {
      id: 'Multigol1_3H',
      label: 'Multi 1-3 Home',
      icon: <MdAlignHorizontalLeft />,
    },
    {
      id: 'Multigol1_3A',
      label: 'Multi 1-3 Away',
      icon: <MdAlignHorizontalRight />,
    },
    { id: 'Under1.5H', label: 'Under 1.5 Home', icon: <BsGraphDown /> },
    { id: 'Under1.5A', label: 'Under 1.5 Away', icon: <BsGraphDown /> },
    // { id: 'Draw', label: 'Draw', icon: <BsGripHorizontal /> },
    { id: 'ExactResult', label: 'Result', icon: <MdSportsSoccer /> },
  ];
  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 mt-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8 text-stroke">
          <FaDice className="inline-block mr-4" />
          Bet Generation
        </h1>
        {isLoading && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex justify-center items-center">
            <TailSpin color="#fff" height={50} width={50} />
          </div>
        )}
        {/* Filtri principali */}
        <div className="mb-5 flex flex-wrap items-center justify-center gap-3">
          <div className="relative">
            <input
              type="date"
              id="matchDate"
              name="matchDate"
              value={selectedDate}
              onChange={handleDateChange}
              ref={dateInputRef}
              className="appearance-none pl-10 pr-4 py-2 h-10 rounded-full text-sm bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Select a date"
            />
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          </div>
          <div className="relative">
            <select
              value={selectedLeague}
              onChange={handleLeagueChange}
              ref={leagueInputRef}
              className="h-10 pl-10 pr-4 rounded-full text-sm bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
            >
              <option value="All">All Leagues</option>
              {leagues.map((league) => (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              ))}
            </select>
            <TbGpsFilled className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          </div>
        </div>

        {/* Bottoni dei tipi di scommessa */}
        <div className="mb-5 flex flex-wrap items-center justify-center gap-4">
          {betTypes.map((betType) => (
            <button
              key={betType.id}
              onClick={() => {
                setSelectedBet(betType.id);
                handleButtonClick(betType.id);
              }}
              className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 bg-white text-gray-700 hover:bg-gray-100`}
            >
              <span className="mr-2">{betType.icon}</span>
              {betType.label}
            </button>
          ))}
          {/* Bottone Clean Table */}
          {tableData.length > 0 && (
            <button
              onClick={() => {
                setTableData([]); // Svuota la tabella
                localStorage.setItem('tableDataBetGen', '');
              }}
              className="flex items-center justify-center bg-white text-purple-600 w-10 h-10 rounded-full border border-purple-600 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
              title="Clean Table"
            >
              <FaTrash />
            </button>
          )}
          {tableData.length > 0 && (
            <button
              onClick={() => {
                setTableData((prevData) => prevData.slice(0, -1));
                if (tableData.length === 1) {
                  localStorage.setItem('tableDataBetGen', '');
                }
              }}
              className="flex items-center justify-center bg-white text-purple-600 w-10 h-10 rounded-full border border-purple-600 hover:bg-purple-100 focus:outline-none focus:ring-purple-500 focus:ring-offset-2"
              title="Remove Last Element"
            >
              <FaUndoAlt />
            </button>
          )}
        </div>

        <div className="overflow-x-auto shadow-md rounded-lg">
          <ToastContainer />
          {tableData && tableData.length > 0 ? (
            <MatchListBetGeneration data={tableData} />
          ) : (
            <div className="text-center py-4 text-gray-200">
              Click the buttons to generate a random list
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BetGenerationPage;
