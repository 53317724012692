import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MatchAnalysisPage } from './MatchAnalysisPage';
// import LeaguePage from './LeaguePage';
import { NotFoundPage } from './NotFoundPage';
import { Navbar } from './Navbar';
import HomeTable from './HomeTable';
import BetGenerationPage from './BetGeneration';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="" element={<HomeTable />} />
          <Route
            path="MatchAnalysis/:matchId"
            element={<MatchAnalysisPage />}
          />
          <Route path="BetGenerationPage" element={<BetGenerationPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

class ProblemComponent extends React.Component {
  render() {
    return <div ref="div" />;
  }
}

export default App;
